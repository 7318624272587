.elon {
    background-color: #6bb5ff;
    color: Black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.elon:hover {
    background-color: #4e9bda;
}

.elon:focus {}

.elon:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.google-map {
    height: 100%;
    width: 100%;
}

.map {
    background-color: #f5f5f7;
}

.elon {
    display: block;
    margin: 3px auto;
}

.flight-info {

    display: flex;
    flex-direction: row;
    border: 5px solid skyblue;
    justify-content: space-between;
    margin: 5px;
}