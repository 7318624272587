/* Containing stylesheet-specific styling ONLY */

@media only screen and (max-width: 350px) {
    .mantine-Drawer-body {
        padding: 0.9em;
    }
}

@media only screen and (max-width: 768px) {
    .mantine-Drawer-body {
        padding: 0.6em;
    }

    .flight-data-content h2 {
        margin: 0.1em;
    }
}

#more-info {
    position: fixed;
    left: -100px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.75s forwards;
    animation-delay: 0.3s;
    top: 50%;
    transform: translate(-50%, -75%);
    border-radius: 100%;
    padding: 0px;
    border: 0px;
    width: fit-content;
    height: fit-content;
    opacity: 0.8;
}

@-webkit-keyframes slide {
    100% {
        left: 45px;
    }
}

@keyframes slide {
    100% {
        left: 45px;
    }
}