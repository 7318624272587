* {
	box-sizing: border-box;
}

p {
	margin: 0px;
}

/* .App {
    color: #424245;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: SF Pro Text,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    background-color: #f5f5f7;
} */

main {
	width: 100%;
	background-color: #f5f5f7;
}

@media screen and (max-width: 600px) {
	.App {
		/* padding: 0 10px; */
	}
}
