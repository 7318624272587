.flights-carousel-container {
    position: relative;
    width: 80%;
    max-width: 1000px;
    margin-top: 1.3em;
}

.flights-carousel-container .caption {
    text-align: center;
    font-style: italic;
    margin-bottom: 0.1em;
}

.flights-carousel {
    position: relative;
}

.flight-cards-container {
    position: relative;
    width: 60%;
    max-width: 1000px;
    margin-top: 2.3em;
}

.flight-cards {
    position: relative;
}

.flight-cards-container .caption {
    margin-left: 2em;
    font-style: italic;
}

.flight {
    min-height: 50px;
    margin: 0.2em 0em;
    text-align: center;
    font-weight: bold;
}

.cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(64px, 100%/4)), 1fr));
    grid-auto-rows: 1fr;
    gap: 10px;
    background-color: #f5f5f7;
    border-radius: 10px;
    margin: 0 auto;
}

.flight-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-height: 150px;
    padding: 0.15em;
    font-size: 1em;
    margin: 6px 3px;
    background-color: mistyrose;
    border-radius: 10px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    cursor: pointer;
    font-family: "JetBrains Mono", monospace;
    list-style: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: box-shadow, transform;
}

.airport-names {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.airport-names span {
    margin-left: 0.1em
}

.flight-cards-container .departure, .flight-cards-container .arrival {
    min-height: 2.5em;
    margin: 0.4em 1em;
    display: flex;
    align-items: flex-start;
}

.flights-carousel-container .departure, .flights-carousel-container .arrival {
    min-height: 4.2em;
    margin: 0.4em 1em;
    display: flex;
    align-items: flex-start;
}

.departure p, .arrival p {
    font-weight: bold;
}

.flight-card:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.flight-card:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.flight-card:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}